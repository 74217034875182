// client/src/components/admin/Dashboard.jsx
import React, { useState, useEffect } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import { Alert, AlertDescription } from '../ui/alert';
import { RefreshCw, AlertCircle, Trash, Clock, Layers, Activity, BarChart2, Bug } from 'lucide-react';
import Button from 'components/ui/Button';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from '../ui/select';
import FlowDebugger from './dashboard/FlowDebugger';
import { FlowList } from './dashboard/FlowList';
import { MetricsDisplay } from './dashboard/MetricsDisplay';
import { useSocket } from 'contexts/SocketContext';
import StageMetrics from './dashboard/StageMetrics';
import Btn from 'Elements/Buttons/Btn';
import { useTheme } from 'components/ui/theme-provider';
import { useTranslation } from 'react-i18next';


const STAGES = {
  questionnaire: 'Questionnaire',
  scoring: 'Scoring',
  recommendation: 'Recommendation',
  reporting: 'Reporting'
};
const fetchMetrics = async (timeRange = '1h') => {

  const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/metrics?timeRange=${timeRange}`);

  if (!response.ok) throw new Error('Failed to fetch metrics');

  return response.json();

};



const fetchFlows = async ({ stage, status }) => {

  const params = new URLSearchParams();

  if (stage !== 'all') params.append('stage', stage);

  if (status !== 'all') params.append('status', status);

  

  const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/flows?${params}`);

  if (!response.ok) throw new Error('Failed to fetch flows');

  return response.json();

};



const fetchSystemHealth = async () => {

  const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/health`);

  if (!response.ok) throw new Error('Failed to fetch system health');

  return response.json();

};
const Dashboard = () => {
  const socket = useSocket();
  const queryClient = useQueryClient();
  const [activeTab, setActiveTab] = useState('overview');
  const [selectedFlow, setSelectedFlow] = useState(null);
  const { theme } = useTheme();
  const {t} = useTranslation();
  const [filters, setFilters] = useState({
    stage: 'all',
    status: 'all',
    timeRange: '1h'
  });
  useEffect(() => {
    console.log(theme);
  }, [theme])
  

  // Socket connection for real-time updates
  useEffect(() => {
    if (!socket) return;

    socket.on('flowUpdate', (update) => {
      queryClient.invalidateQueries(['flows']);
      queryClient.invalidateQueries(['adminMetrics']);
    });

    return () => {
      socket.off('flowUpdate');
    };
  }, [socket, queryClient]);

  // Updated queries with filters
  const { data: metrics, isLoading: isLoadingMetrics } = useQuery({
    queryKey: ['adminMetrics', filters.timeRange],
    queryFn: fetchMetrics,
    refetchInterval: 100000
  });

  const { data: flows } = useQuery({
    queryKey: ['flows', filters],
    queryFn: () => fetchFlows(filters),
    refetchInterval: 50000
  });

  const { data: healthData } = useQuery({
    queryKey: ['systemHealth'],
    queryFn: fetchSystemHealth,
    refetchInterval: 55000
  });

  const renderFilters = () => (
    <div className="rounded-lg shadow-sm p-4 mb-6">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 ">
        <Select
          value={filters.timeRange}
          onValueChange={(value) => setFilters((prev) => ({ ...prev, timeRange: value }))}
        >
          <SelectTrigger className="w-full border-gray-200">
            <div className="flex items-center">
              <Clock className="w-4 h-4 mr-2 " />
              <SelectValue placeholder={t('adminDashboard.filters.timeRange')} />
            </div>
          </SelectTrigger>
          <SelectContent className={`${theme=="dark" ? 'text-white' : 'text-black'
                          }`}>
            <SelectItem value="1h">{t('adminDashboard.timeRanges.1h')}</SelectItem>
            <SelectItem value="24h">{t('adminDashboard.timeRanges.24h')}</SelectItem>
            <SelectItem value="7d">{t('adminDashboard.timeRanges.7d')}</SelectItem>
          </SelectContent>
        </Select>

        <Select
          value={filters.stage}
          onValueChange={(value) => setFilters((prev) => ({ ...prev, stage: value }))}
        >
          <SelectTrigger className="w-full border-gray-200">
            <div className="flex items-center">
              <Layers className="w-4 h-4 mr-2" />
              <SelectValue placeholder={t('adminDashboard.filters.stage')} />
            </div>
          </SelectTrigger>
          <SelectContent className={`${theme=="dark" ? 'text-white' : 'text-black'
                          }`}>
            <SelectItem value="all">{t('allstages')}</SelectItem>
            <SelectItem value="questionnaire">{t('questionnaire')}</SelectItem>
            <SelectItem value="scoring">{t('scoring')}</SelectItem>
            <SelectItem value="recommendation">{t('recommendation')}</SelectItem>
            <SelectItem value="reporting">{t('reporting')}</SelectItem>
          </SelectContent>
        </Select>

        <Select
          value={filters.status}
          onValueChange={(value) => setFilters((prev) => ({ ...prev, status: value }))}
        >
          <SelectTrigger className="w-full border-gray-200">
            <div className="flex items-center">
              <Activity className="w-4 h-4 mr-2 text-gray-500" />
              <SelectValue placeholder={t('adminDashboard.filters.status')} />
            </div>
          </SelectTrigger>
          <SelectContent className={`${theme=="dark" ? 'text-white' : 'text-black'
                          }`}>
            <SelectItem value="all">{t('allStatuses')}</SelectItem>
            <SelectItem value="active">{t('active')}</SelectItem>
            <SelectItem value="completed">{t('completed')}</SelectItem>
            <SelectItem value="error">{t('error')}</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  );

  const renderOverviewTab = () => (
    <div className="space-y-6">
      <MetricsDisplay metrics={metrics} />
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
        {Object.entries(STAGES).map(([stage, label]) => (
          <StageMetrics
            key={stage}
            stage={label}
            metrics={metrics?.stageDetails?.[stage] || {
              active: 0,
              completed: 0,
              failed: 0,
              avgProcessingTime: 0
            }}
          />
        ))}
      </div>
    </div>
  );

  const renderDebugTab = () => (
    <div className="space-y-4">
      {selectedFlow ? (
        <FlowDebugger
          flow={selectedFlow}
          onClose={() => setSelectedFlow(null)}
        />
      ) : (
        <Alert>
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>
            Select a flow from the Flows tab to debug
          </AlertDescription>
        </Alert>
      )}
    </div>
  );
  const renderHeader = () => (
    <div className="top-0 px-6 py-4 border-b border-gray-200">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <div>
          <h1 className="text-2xl sm:text-3xl font-bold">{t('adminDashboard.title')}</h1>
          <p className="text-sm text-muted-foreground mt-1">{t('adminDashboard.subtitle')}</p>
        </div>
        <div className="flex flex-col sm:flex-row items-stretch sm:items-center gap-3 w-full sm:w-auto">
          <Btn
            onClick={() => queryClient.invalidateQueries()}
            variant="outline"
            className="border-gray-200 flex-1 sm:flex-none justify-center"
          >
            <RefreshCw className="w-4 h-4 mr-2" />
            {t('adminDashboard.refresh')}
          </Btn>
          <Btn
            onClick={() => clearCache('all')}
            variant="destructive"
            className="bg-red-500 hover:bg-red-600 flex-1 sm:flex-none justify-center"
          >
            <Trash className="w-4 h-4 mr-2" />
            {t('adminDashboard.clearCache')}
          </Btn>
        </div>
      </div>
    </div>
  );

  if (isLoadingMetrics) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="flex flex-col items-center gap-4">
          <RefreshCw className="w-8 h-8 animate-spin text-primary" />
          <p className={`text-sm ${theme === 'dark' ? 'text-gray-400' : 'text-muted-foreground'}`}>
            {t('adminDashboard.loadingMetrics')}
          </p>
        </div>
      </div>
    );
  }
  const clearCache = async (type = 'all') => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/admin/cache/clear`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ type })
      });
      
      if (!response.ok) {
        throw new Error('Failed to clear cache');
      }
      
      // Refresh your UI or metrics
      queryClient.invalidateQueries(['adminMetrics']);
    } catch (error) {
      console.error('Error clearing cache:', error);
      // Handle error in UI
    }
  };
  const renderTabs = () => (
    <div className="rounded-lg shadow-sm overflow-hidden border">
      <Tabs value={activeTab} onValueChange={setActiveTab} className="w-full">
        <TabsList className="w-full border-b bg-gray-50/50">
          <div className="flex overflow-x-auto no-scrollbar">
            <TabsTrigger value="overview" className="flex-1 sm:flex-none">
              <BarChart2 className="w-4 h-4 mr-2" />
              {t('adminDashboard.overview')}
            </TabsTrigger>
            <TabsTrigger value="flows" className="flex-1 sm:flex-none">
              <Activity className="w-4 h-4 mr-2" />
              {t('adminDashboard.activeFlows')}
            </TabsTrigger>
            <TabsTrigger value="debug" className="flex-1 sm:flex-none">
              <Bug className="w-4 h-4 mr-2" />
              {t('adminDashboard.debug')}
            </TabsTrigger>
          </div>
        </TabsList>
        <div className="p-4 sm:p-6">
          <TabsContent value="overview" className="mt-0">
            {renderOverviewTab()}
          </TabsContent>
          <TabsContent value="flows" className="mt-0">
            <div className="overflow-x-auto">
              <FlowList
                flows={flows?.flows || []}
                onSelect={setSelectedFlow}
                selectedId={selectedFlow?.sessionId}
              />
            </div>
          </TabsContent>
          <TabsContent value="debug" className="mt-0">
            {renderDebugTab()}
          </TabsContent>
        </div>
      </Tabs>
    </div>
  );


  return (
    <div className={`min-h-screen `}>
       <div className="p-6">
      {renderHeader()} 
      {renderFilters()}
      {renderTabs()} 
    </div>
    </div>
   
  );
};

export default Dashboard;
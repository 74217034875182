import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Search, Bell, User, Settings, LogOut, Moon, Sun, Globe } from 'lucide-react';
import Logo from "../../components/CommonComponent/LogoWrapper/Logo";
import logoOne from "../../assets/images/logo/1.png";
import SettingContext from "../../Helpers/SettingContext";
import Sidebar from "Layout/Sidebar";
import ProfileNav from "./ProfileNav";
import useOutsideDropdown from "utils/Hooks/CustomHooks/useOutsideDropdown";
import { FiLogOut } from "react-icons/fi";
import ShowModal from "Elements/Alerts&Modals/Modal";
import { useTranslation } from "react-i18next";
import { useTheme } from "components/ui/theme-provider";

const Header = ({ isSidebarOpen, setIsSidebarOpen, darkMode, toggleDarkMode }) => {
  const [mounted, setMounted] = useState(true);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [modal, setModal] = useState(false);
  const { theme, setTheme } = useTheme();
  const { t,i18n } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => setMounted(false), 700);
    return () => clearTimeout(timer);
  }, []);

  const handleDarkModeToggle = () => {
    const newMode = !darkMode; // Toggle the current mode
    toggleDarkMode(newMode); // Update the state
    localStorage.setItem("theme", newMode ? "dark" : "light"); // Persist to localStorage
    document.body.style.backgroundColor = newMode ?'#111827' : '#f9fafb';
    document.body.style.color = newMode === 'dark' ? '#fff' : '#111827';

    // Apply the dark or light class to the root element
    const root = document.documentElement;
    root.classList.remove("dark", "light");
    root.classList.add(newMode ? "dark" : "light");
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };
  const changeLanguage = (e) => {
    i18n.changeLanguage(e.target.value);
  };

  useEffect(() => {
    // Initialize dark mode based on localStorage or system preferences
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme === "dark" || (!savedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
      toggleDarkMode(true);
      document.documentElement.classList.add("dark");
    } else {
      toggleDarkMode(false);
      document.documentElement.classList.add("light");
    }
  }, []);

  return (
    <header
      className={`
        fixed top-0 right-0 z-30 w-full dark:bg-gray-800
        border-b border-gray-200 dark:border-gray-700
        transition-all duration-300 ease-in-out
        ${isSidebarOpen ? "lg:ml-64" : ""}
        ${mounted ? "animate-pulse" : ""}
        ${darkMode ?" bg-gray-900 text-white" : "bg-white"}
      `}
    >
      <div className="px-4 py-3 lg:px-6">
        <div className="flex items-center justify-between">
          {/* Left - Logo and Sidebar Toggle */}
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="p-2 rounded-lg text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
            >
              <Menu className="w-6 h-6" />
            </button>
            <Link to="/" className="flex items-center space-x-3">
              <img src={logoOne} alt="Logo" className="h-8" />
              <span className="hidden md:block text-xl font-bold text-gray-800 dark:text-white">Your Logo</span>
            </Link>
          </div>

          {/* Right - Actions */}
          <div className="flex items-center space-x-4">
            <button
              onClick={handleDarkModeToggle}
              className="p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              {darkMode ? <Moon className="w-5 h-5 text-gray-500" /> :<Sun className="w-5 h-5 text-yellow-500" /> }
            </button>

            {/* Language Selector */}
            <select
              onChange={changeLanguage}
            className={`p-2 rounded-lg  border border-gray-300 dark:border-gray-600 focus:outline-none  ${darkMode ?" bg-gray-900 text-white" : "bg-white"}`}
            >
              <option value="en">🇬🇧 English</option>
              <option value="et">🇪🇪 Estonian</option>
              <option value="ru">🇷🇺 Russian</option>
            </select>
            <div className="relative">
            <button
                onClick={() => setShowUserMenu(!showUserMenu)}
                className="flex items-center space-x-2 p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <img
                  src="https://via.placeholder.com/40"
                  alt="User"
                  className="w-8 h-8 rounded-full"
                />
                <span className="hidden md:block text-sm font-medium text-gray-700 dark:text-gray-200">
                  John Doe
                </span>
              </button>

              {showUserMenu && (
                <div className="absolute right-0 mt-2 w-48 rounded-lg shadow-lg 
                               border border-gray-200 dark:border-gray-700">
                  <div className="py-1">
                    <Link to="/admin/profile" className={`flex items-center px-4 py-2 text-sm ${darkMode ?"   dark:text-gray-200  dark:hover:bg-gray-700" : " text-gray-700 hover:bg-gray-100"}`}>
                      <User className="w-4 h-4 mr-2" />
                      Profile
                    </Link>
                    <Link to="/admin/settings" className={`flex items-center px-4 py-2 text-sm ${darkMode ?"   dark:text-gray-200  dark:hover:bg-gray-700" : " text-gray-700 hover:bg-gray-100"}`}>
                      <Settings className="w-4 h-4 mr-2" />
                      Settings
                    </Link>
                    <button   onClick={() => setModal(true)} className={`w-full flex items-center px-4 py-2 text-sm ${darkMode ?"   dark:text-red-500  dark:hover:bg-gray-700" : " text-red-500  hover:bg-gray-100"}`}>
                      <FiLogOut className="w-4 h-4 mr-2" />
                      <span>Logout</span>
                    </button>

                  </div>

                </div>
              )}
            </div>
            
          </div>

        </div>
      </div>
      <ProfileNav modal={modal} setModal={setModal}/>
    </header>
  );
};


export default Header;
